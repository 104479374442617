import React from 'react';
// import useTranslations from '../useTranslations';
import Navigation, {NavigationLanguage} from '../Navigation';
// import Languages from '../Languages';
import ButtonMenu, {ButtonLanguage} from '../ButtonMenu';
// import Logo from '../Logo';

import { useMenu } from '../../hooks/menu';

import * as S from './styled';
import {useLocale} from "../../hooks/locale";

const Header = () => {
  // const { home } = useTranslations();
  const { openedMenu, toggleMenu, closeMenu } = useMenu();
  const { openedLanguageMenu, toggleLanguage } = useLocale();

  return (
    <S.HeaderWrapper>
      <nav>
      <S.Container>
        {/*<S.LogoLink to="/" title={home} aria-label={home}>*/}
        {/*  <Logo />*/}
        {/*</S.LogoLink>*/}
        <S.ButtonMenu className={openedLanguageMenu ? 'is-active' : ''}>
          <ButtonLanguage handleClick={toggleLanguage} isActive={openedLanguageMenu}/>
        </S.ButtonMenu>

        <S.NavLanguages className={openedLanguageMenu ? 'is-active' : ''}>
          <NavigationLanguage />
        </S.NavLanguages>

        <S.ButtonMenu className={openedMenu ? 'is-active' : ''}>
          <ButtonMenu handleClick={toggleMenu} isActive={openedMenu} />
        </S.ButtonMenu>

        <S.NavMenu className={openedMenu ? 'is-active' : ''}>
          <Navigation handleClick={closeMenu} isActive={openedMenu}/>
        </S.NavMenu>
      </S.Container>
      </nav>
    </S.HeaderWrapper>
  );
};

export default Header;
