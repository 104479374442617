import React from "react"
// import {graphql, Link, useStaticQuery} from "gatsby"
// import LocalizedLink from "./localizedLink"
import Menu from '../menu';
// import useTranslations from "./useTranslations"
// import Languages from "../Languages";

import * as S from './styled';
import Languages from "../Languages";

const Navigation = props => {
    const menuItems = Menu()
    // const {backToHome} = useTranslations()

    return (
            <>
                <S.Navigation
                    onClick={props.handleClick}
                    className={props.isActive ? 'active' : ''}
                >
                    {menuItems.map((menu, index) => (
                        <S.NavigationLink
                            to={menu.link}
                            aria-label={menu.name}
                            activeClassName="active"
                            key={`${menu.link}${index}`}
                        >
                            {menu.name}
                        </S.NavigationLink>
                    ))}
                </S.Navigation>
            </>
    )
}

export default Navigation

export const NavigationLanguage = () => {
    return Languages()
}