import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../localizedLink';

export const HeaderWrapper = styled.div`
  //background-color: var(--bg-light);
  //background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  background: var(--gray-very-extra-light);
  height: var(--header-height);
  ${media.greaterThan('large')`
    margin-bottom: var(--space);
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--width-container);
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: calc(var(--space) * 0.8) var(--space);
  position: relative;
  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
  ${media.greaterThan('large')`
    padding: calc(var(--space)*0.8) var(--space-sm);
  `}
`;

export const LogoLink = styled(LocalizedLink)`
  display: inline-block;
  margin-right: 0.5rem;
  width: 170px;
`;

export const ButtonMenu = styled.div`
  &.is-active {}
`;

export const NavMenu = styled.div`
  ${media.greaterThan('medium')`
    margin-left: auto;
    width: auto;
    display: block;

  `}
  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: var(--header-height);
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    &.is-active {
      background: var(--gray-high-extra-light);
    }
  `}
  &.is-active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }  
`;

export const NavLanguages = styled.div`
  width: 105px;
  height: 40px;
  display: none;
  background: var(--gray-high-extra-light);  
  &.is-active {
    display: block;
    position: absolute;
    border-radius: 6px;
    top: 15px;
    left: 50px;
  }
`;
