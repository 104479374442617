import React from 'react';
import useTranslations from '../useTranslations';

import * as S from './styled';

const CCLicense = () => {
    const {
        license,
        license_url
    } = useTranslations();

    return (
        <S.LicenseLinksLink
            rel="license nofollow noopener noreferrer"
            href={license_url}
            target="_blank">
            <img
                alt={license}
                title={license}
                src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
                width={88}
                height={31}
            />
        </S.LicenseLinksLink>
    );
};


export default CCLicense;
