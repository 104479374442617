import React from 'react';
import {graphql, navigate, useStaticQuery} from "gatsby";
import {findKey} from "../../utils/gatsby-node-helpers";

import * as S from './styled';
// import {useLocale} from "../../hooks/locale";
import {LocaleContext} from "../../layouts/baseLayout";

const locales = require('../../../config/i18n')


const Languages = () => {
      // Grab the locale (passed through context) from the Locale Provider
      // through useLocale() hook
      const {locale} = React.useContext(LocaleContext)

      const {rawData} = useStaticQuery(query);

      function handleClickLanguage(e, lang) {
        e.preventDefault();
        if (locale === lang) return;

        const defaultLang = findKey(locales, o => o.default === true)
        const url = window.location.pathname.split("/").filter(e => e);

        if (url.length === 0 || (url.length === 1 && locale !== defaultLang))
          return lang === defaultLang ?
              navigate(`/`) :
              navigate(`/${lang}`)

        let relativePath
        if (locale === defaultLang)
          relativePath = url.slice(0, url.length)
        else
          relativePath = url.slice(1, url.length)

        if (lang === defaultLang) {
          return navigate(`/${relativePath}`)
        } else {
          return navigate(`/${lang}/${relativePath}`)
        }


      }

      const languages = rawData.nodes.map((key) => ([key.path, key.localeDescription]))

      return (
          <S.LanguageWrapper>
              {languages.map((lang, index) =>
                  <S.LanguageItem key={`${lang}${index}`}>
                    <S.LanguageLink
                        to="/"
                        onClick={(e) => handleClickLanguage(e, lang[0])}
                        className={locale === lang[0] ? 'is-active' : ''}>
                        {lang[1]} ({lang[0].toUpperCase()})
                    </S.LanguageLink>
                  </S.LanguageItem>
              )}
          </S.LanguageWrapper>
      )
    }
;

export default Languages;

const query = graphql`
  query languages {
    rawData:   allTranslationsJson {
        nodes {
            path
            localeDescription
        }
    }
  }
`

//     <ul>
//         {rawData.edges.map(({node: lang}) => (
//             <li>
//                 <Link
//                     to={lang.path}
//                     onClick={(e) => handleClickLanguage(e, "en")}
//                     className={locale === "en" ? 'is-active' : ''}>patata
//                     {/*{lang.translations.path}*/}
//                 </Link>
//             </li>
//         ))}
//     </ul>