import React from 'react';

import {Linkedin} from '@styled-icons/boxicons-logos/Linkedin'
import {Github} from '@styled-icons/boxicons-logos/Github';

import * as S from './styled';

const SocialLinks = () => {
    return (
        <S.SocialLinksList>
            <S.SocialLinksItem>
                <S.SocialLinksLink
                    href="https://github.com/jneilaglez"
                    title="Github"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                >
                    <Github/>
                </S.SocialLinksLink>
            </S.SocialLinksItem>
            <S.SocialLinksItem>
                <S.SocialLinksLink
                    href="https://www.linkedin.com/in/jes%C3%BAs-neila-gonz%C3%A1lez-a4a8528b/"
                    title="LinkedIn"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                >
                    <Linkedin/>
                </S.SocialLinksLink>
            </S.SocialLinksItem>
        </S.SocialLinksList>
    );
};

export default SocialLinks;
