import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from "../localizedLink";

export const LanguageWrapper = styled.ul`
  padding-top: 1px;
  ${media.lessThan("large")`{
    padding-top: 3px;
  `}
`;

export const LanguageItem = styled.li`
  margin-left: var(--space-sm);
`;

export const LanguageLink = styled(LocalizedLink)`
  display: inline-block;
  margin-right: 0.5rem;
  color: var(--gray);
  font-size: 1.4rem;
  text-decoration: none;

  &.is-active {
    color: var(--thirdy-color);
    font-weight: bold;
  }
  &:hover {
    color: var(--thirdy-color)
  }
`;
